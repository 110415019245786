import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'modal', 'button', 'submit']

  initialize() {
    // options with default values
    this.options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'download-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
        document.querySelectorAll('.download-modal-backdrop').forEach((element) => {
          element.remove();
        });
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };
  }

  connect() {
    // instance options object
    this.modal = new Modal(this.modalTarget, this.options, this.instanceOptions);
  }

  buttonTargetConnected(button) {
    button.addEventListener('click', this.openModal.bind(this))
  }

  buttonTargetDisconnected(button) {
    button.removeEventListener('click', this.openModal.bind(this))
  }

  openModal(event) {
    console.log(event.target.dataset.listId)
    this.inputTarget.value = event.target.dataset.listId ?? '';
    this.modal.show();
  }

  loadDownloadOptions(e) {
    if (e.detail.success) {
      this.modal.show();
    }
  }

  closeModal() {
    this.modal.hide();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.modal.hide();
    }
  }
}
