import { Controller } from "@hotwired/stimulus";
import Choices from 'choices.js';

const classNames = {
  containerOuter: 'choices',
  containerInner: 'choices__inner',
  input: 'choices__input',
  inputCloned: 'choices__input--cloned',
  list: 'choices__list',
  listItems: 'choices__list--multiple',
  listSingle: 'choices__list--single',
  listDropdown: 'choices__list--dropdown',
  item: 'choices__item',
  itemSelectable: 'choices__item--selectable',
  itemDisabled: 'choices__item--disabled',
  itemChoice: 'choices__item--choice',
  placeholder: 'choices__placeholder',
  group: 'choices__group',
  groupHeading: 'choices__heading',
  button: 'choices__button',
  activeState: 'is-active',
  focusState: 'is-focused',
  openState: 'is-open',
  disabledState: 'is-disabled',
  highlightedState: 'is-highlighted',
  selectedState: 'is-selected',
  flippedState: 'is-flipped',
  loadingState: 'is-loading',
  noResults: 'has-no-results',
  noChoices: 'has-no-choices'
};

export default class extends Controller {
  static targets = ['select', 'options', 'mode'];

  initialize() {
    try {
      this.element['choices'] = this;
      this.searchPath = this.element.dataset.searchPath;
      this.asyncPath = this.element.dataset.asyncPath;
      this.forceOption = this.element.dataset.forceOption || true;
      this.key = this.selectTarget.dataset.key;
      this.isDropdown = this.element.dataset.dropdown || false;

      // Bind all methods
      this.bindMethods([
        'refresh', 'async', 'add', 'clear', 'remove',
        'search', 'update', 'filter', 'options',
        'setChoicesValue', 'addItemOnCustomKeyPress',
        'optionsReducer', 'updateActiveSearch', 'append'
      ]);
    } catch (error) {
      console.error("Error in initialize:", error);
    }
  }

  bindMethods(methods) {
    try {
      methods.forEach(method => {
        if (typeof this[method] === 'function') {
          this[method] = this[method].bind(this);
        } else {
          console.warn(`Method "${method}" is not defined or not a function.`);
        }
      });
    } catch (error) {
      console.error("Error in bindMethods:", error);
    }
  }

  connect() {
    try {
      setTimeout(this.setup.bind(this), 5);
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  modeTargetConnected(checkbox) {
    try {
      checkbox.addEventListener("change", () => {
        this.dispatch("change", { detail: { field_name: 'mode', field_value: checkbox.checked } });
      });
    } catch (error) {
      console.error("Error in modeTargetConnected:", error);
    }
  }

  setup() {
    try {
      const that = this;
      let choicesOptions = {
        ...this.options(),
        removeItemButton: true,
        valueComparer: (a, b) => a.trim() === b.trim(),
        fuseOptions: { threshold: 0.1 },
        noResultsText: () => {
          if (that.isDropdown) {
            return `No results found`;
          } else {
            return `Press Enter to add <b>"${this.choices.input.value}"</b>`;
          }
        }
      };

      if (!this.isDropdown) {
        choicesOptions.addItemFilter = (value) => {
          this.addItemOnCustomKeyPress(value);
          return true;
        }
      }

      this.choices = new Choices(this.selectTarget, choicesOptions);
      this.input = this.element.querySelector('input');
      this.temporaryValue = null;

      this.refresh();
      this.async();
      this.updateActiveSearch('init');

      if (this.searchPath) {
        this.input.addEventListener('input', this.search);
        this.selectTarget.addEventListener('change', this.refresh);
        this.selectTarget.addEventListener('addItem', this.add);
        this.selectTarget.addEventListener('removeItem', this.remove);
      } else {
        this.setupDefaultListeners();
      }
    } catch (error) {
      console.error("Error in setup:", error);
    }
  }

  setupDefaultListeners() {
    try {
      this.selectTarget.addEventListener('change', () => {
        this.updateActiveSearch('change');
      });
      if (!this.isDropdown) {
        this.choices.input.element.addEventListener("keydown", (event) => {
          if (event.key === "Enter") {
            this.setChoicesValue(this.choices.input.value);
            const temporaryOption = this.choices.passedElement.element.querySelector('.temporary');
            if (temporaryOption) temporaryOption.remove();
          }
        });

        this.choices.input.element.addEventListener("input", (event) => {
          this.removeTemporaryOptions();
          if (event.key !== 'Backspace' && event.key !== 'Delete') {
            this.temporaryValue = this.choices.input.value.trim();
            this.choices.setValue([this.temporaryValue]);
          }
        });
      }
    } catch (error) {
      console.error("Error in setupDefaultListeners:", error);
    }
  }

  removeTemporaryOptions() {
    try {
      if (this.temporaryValue) {
        const temporaryOptions = this.choices.passedElement.element.querySelectorAll(`option[value="${this.temporaryValue}"]`);
        temporaryOptions.forEach(option => option.remove());

        const temporaryItems = this.choices.dropdown.element.querySelectorAll(`[data-value="${this.temporaryValue}"]`);
        temporaryItems.forEach(item => item.remove());
      }
    } catch (error) {
      console.error("Error in removeTemporaryOptions:", error);
    }
  }

  disconnect() {
    try {
      if (this.searchPath) {
        this.input.removeEventListener('input', this.search);
        this.selectTarget.removeEventListener('change', this.refresh);
        this.selectTarget.removeEventListener('addItem', this.add);
        this.selectTarget.removeEventListener('removeItem', this.remove);
      }

      if (this.choices) {
        this.choices.destroy();
        this.choices = undefined;
      }
    } catch (error) {
      console.error("Error in disconnect:", error);
    }
  }

  refresh() {
    try {
      this.choices.setChoices([], 'value', 'label', true);
      if (this.hasOptionsTarget) {
        [...this.optionsTarget.children].forEach(this.append);
      }
    } catch (error) {
      console.error("Error in refresh:", error);
    }
  }

  async async() {
    try {
      if (this.asyncPath) {
        this.choices.setChoices(async () => {
          const response = await fetch(this.asyncPath);
          return response.json();
        }, 'value', 'label', false);

        const value = this.selectTarget.getAttribute('value');
        if (value) {
          this.selectTarget.value = value;
          this.choices.setChoiceByValue(value);
        }
      }
    } catch (error) {
      console.error("Error in async:", error);
    }
  }

  clear() {
    try {
      this.choices.removeActiveItems();
      this.choices.removeActiveItemsByValue(this.choices.getValue(true));
      this.updateActiveSearch('clear');
    } catch (error) {
      console.error("Error in clear:", error);
    }
  }

  append(option) {
    try {
      if (![...this.selectTarget.options].some(o => o.label === option.label)) {
        this.choices.setChoices([option], 'value', 'label', false);
      }
    } catch (error) {
      console.error("Error in append:", error);
    }
  }

  add(event) {
    try {
      if (this.hasOptionsTarget) {
        const option = [...this.optionsTarget.children].find(opt => opt.label === event.detail.label);
        if (option) {
          option.setAttribute('selected', '');
        } else {
          const newOption = document.createElement('option');
          newOption.setAttribute('label', event.detail.label);
          newOption.setAttribute('value', event.detail.value);
          newOption.setAttribute('selected', '');
          this.optionsTarget.appendChild(newOption);
        }
      }
    } catch (error) {
      console.error("Error in add:", error);
    }
  }

  remove(event) {
    try {
      if (this.hasOptionsTarget) {
        const option = [...this.optionsTarget.children].find(opt => opt.label === event.detail.label);
        if (option) {
          this.searchPath ? option.remove() : option.removeAttribute('selected');
        }
      }

      if (this.forceOption && !this.selectTarget.options.length) {
        this.selectTarget.add(document.createElement('option'));
      }
    } catch (error) {
      console.error("Error in remove:", error);
    }
  }

  search(event) {
    try {
      if (event.target.value) {
        fetch(this.searchPath + event.target.value, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
          .then(response => response.json())
          .then(this.update)
          .catch(error => console.error("Error fetching search results:", error));
      } else {
        this.refresh();
      }
    } catch (error) {
      console.error("Error in search:", error);
    }
  }

  update(data) {
    try {
      this.choices.setChoices(data.filter(this.filter), 'value', 'label', true);
    } catch (error) {
      console.error("Error in update:", error);
    }
  }

  filter(item) {
    try {
      return ![...this.selectTarget.options].some(option => option.label === item.label);
    } catch (error) {
      console.error("Error in filter:", error);
      return false;
    }
  }

  options() {
    try {
      return 'silent renderChoiceLimit maxItemCount addItems removeItems removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText'
        .split(' ')
        .reduce(this.optionsReducer, { classNames: classNames });
    } catch (error) {
      console.error("Error in options:", error);
      return {};
    }
  }

  optionsReducer(accumulator, currentValue) {
    try {
      if (currentValue in this.element.dataset || this.element.dataset.hasOwnProperty(currentValue)) {
        let value = this.element.dataset[currentValue];
        accumulator[currentValue] = ['true', 'false'].includes(value) ? JSON.parse(value) : value;
      }
      return accumulator;
    } catch (error) {
      console.error(`Error in optionsReducer for "${currentValue}":`, error);
      return accumulator;
    }
  }

  setChoicesValue(value) {
    try {
      if (value) {
        this.choices.setValue([value]);
        this.choices.setChoiceByValue(value);
        this.choices.clearInput();
        this.choices.hideDropdown(true);
        this.updateActiveSearch('select');
      }
    } catch (error) {
      console.error("Error in setChoicesValue:", error);
    }
  }

  addItemOnCustomKeyPress(value) {
    try {
      const addItemCharacters = ',; ';
      if (this.choices && value && addItemCharacters.includes(value.slice(-1))) {
        const values = value.slice(0, -1);
        localStorage.setItem(this.key, JSON.stringify(values));
        this.setChoicesValue(values);
        this.updateActiveSearch('add');
      } else {
        localStorage.setItem(this.key, JSON.stringify([]));
        this.updateActiveSearch('add');
      }
    } catch (error) {
      console.error("Error in addItemOnCustomKeyPress:", error);
    }
  }

  updateActiveSearch(action = 'change') {
    try {
      const accordion = document.getElementById(`accordion-flush-${this.key}`);
      const accordionBody = document.getElementById(`accordion-flush-body-${this.key}`);
      const values = this.choices.getValue(true);

      if (accordion) {
        const badge = accordion.querySelector(`.badge-${this.key}`);
        if (values.length > 0) {
          accordion.classList.add('filled-search');
          accordionBody.classList.add('filled-search');
          badge.innerHTML = values.length;
        } else {
          accordion.classList.remove('filled-search');
          accordionBody.classList.remove('filled-search');
          badge.innerHTML = values.length;
        }
      }

      this.dispatch(action, { detail: { field_name: this.key, field_value: values } });
    } catch (error) {
      console.error("Error in updateActiveSearch:", error);
    }
  }
}
