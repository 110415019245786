import { Controller } from "@hotwired/stimulus"
import { EditorState } from "@codemirror/state";
import { EditorView, keymap, lineNumbers, highlightActiveLine } from "@codemirror/view";
import { yaml } from "@codemirror/lang-yaml";
import { defaultKeymap, history } from "@codemirror/commands";

export default class extends Controller {
  static targets = ["editorLeft", "editorRight"];

  connect() {
    const code1 = `full_name
first_name
last_name
personal email address
businss email address
personal phone numbers
business phone numbers
gender
linkedin url
address
city
country
job title
job summary
job title role
job title level
job company_name
job company_website
job description
 |-- job company linkedin url
 |-- education
 |    |    |-- date
 |    |    |-- degree
 |    |    |-- major
 |    |    |-- school
 |    |    |    |-- domain
 |    |    |    |-- lnkdedin url
 |    |    |    |-- name
 |    |    |-- summary`;
 const code2 = ` |-- experience
 |    |    |-- company
 |    |    |    |-- domain
 |    |    |    |-- lnkdedin url
 |    |    |    |-- name
 |    |    |-- date1
 |    |    |-- department
 |    |    |-- description
 |    |    |-- location
 |    |    |-- seniority
 |    |    |-- title
 interests
 skills
 languages spoken
 region
 profile picture url
 job company size
 industry
 job title detailed role
 organization founded year
 organization facebook url
 organization twitter url
 organization current technologies
 state
 person location postal code
 organization hq location city
 organization hq location state
 organization hq location country
 organization hq location postal code`;
    // Create editor state for left and right editors
    const stateLeft = EditorState.create({
      doc: code1,
      extensions: [
        yaml(),
        lineNumbers({ value: lineNumber => `${lineNumber + 1}` }), // Line number starting from 1
        highlightActiveLine(),
        EditorView.editable.of(false),
        keymap.of(defaultKeymap)
      ]
    });

    const stateRight = EditorState.create({
      doc: code2,
      extensions: [
        yaml(),
        lineNumbers({ formatNumber: function(lineNumber, state) { return `${lineNumber + 29}` } }), // Adjusted line number
        highlightActiveLine(),
        EditorView.editable.of(false),
        keymap.of(defaultKeymap)
      ]
    });

    // Initialize the left editor
    this.viewLeft = new EditorView({
      state: stateLeft,
      parent: this.editorLeftTarget
    });

    // Initialize the right editor
    this.viewRight = new EditorView({
      state: stateRight,
      parent: this.editorRightTarget
    });
  }
}
