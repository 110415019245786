import { Controller } from "@hotwired/stimulus"
import { CountUp } from "countup.js"
import Swiper from "swiper"

export default class extends Controller {
  static targets = ["prospectCounter", "userCounter", "tabSlide", "customerSlide", "pagination"]

  connect() {
    if (document.getElementById('prospectCounter')) {
      const prospectCounter = new CountUp('prospectCounter', this.prospectCounterTarget.dataset.counter);
      if (!prospectCounter.error) {
        prospectCounter.start();
      } else {
        prospectCounter.error(countUp.error);
      }
    }

    if (document.getElementById('userCounter')) {
      const userCounter = new CountUp('userCounter', this.userCounterTarget.dataset.counter);
      if (!userCounter.error) {
        userCounter.start();
      } else {
        console.error(userCounter.error);
      }
    }

    if (this.tabSlideTarget) {
      this.swiper = new Swiper(this.tabSlideTarget, {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        // Swiper parameters
        slidesPerView: 1,
        spaceBetween: 10,
        // Remove default pagination
        pagination: {
          el: null,
        },
        on: {
          slideChange: () => this.updatePagination(),
        },
      });
    }

    if (this.customerSlideTarget) {
      this.customer = new Swiper(this.customerSlideTarget, {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        // Swiper parameters
        slidesPerView: 7,
        spaceBetween: 10,
        // Remove default pagination
        pagination: {
          el: null,
        }
      });
    }

    // Update pagination on initial load
    this.updatePagination = this.updatePagination.bind(this);
    this.updatePagination();

    document.addEventListener('scroll', this.stickyHeader.bind(this))
  }

  disconnect() {
    document.removeEventListener('scroll', this.stickyHeader.bind(this))
  }

  goToSlide(event) {
    const slideIndex = event.target.dataset.slideIndex;
    this.swiper.slideToLoop(slideIndex);
    this.updatePagination(); // Ensure the active class is updated
  }

  updatePagination() {
    const realIndex = this.swiper.realIndex; // Get the real index in the loop
    this.paginationTarget.querySelectorAll('li').forEach((el, index) => {
      el.classList.toggle('active', index == realIndex);
      el.classList.toggle('flex-1', index == realIndex);
    });
  }

  stickyHeader() {
    const header = document.getElementById('header');
    if (header) {
      const scrollY = window.scrollY;

      if (scrollY > 0) {
        header.classList.add('bg-white');
        header.classList.remove('bg-transparent');
      } else {
        header.classList.remove('bg-white');
        header.classList.add('bg-transparent');
      }
    }
  }
}
