import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js"

const tips = [
  "Nurture leads on social media.",
  "Everyone tells you something for a reason, Every word a prospect uses is trying to tell you something.",
  "The master has failed more times than the beginner has even tried.",
  "Get your No's Faster. Don’t drag it out. They keep you from focusing on the legit deals.",
  "Let the customer do most of the talking.",
  "Make the customer fall in love with your product or service.",
  "People don’t care how much you know until they know how much you care.",
  "Don’t overshare yourself out of a deal.",
  "Less is more.",
  "Problems equal opportunities.",
  "Sales is communication, communication is a transfer of energy! So keep motivated and enthusiastic, the prospect will definitely feel that energy too.",
  "Sometimes it's better to actively listen to the customer rather than pitch them features.",
  "Always be closing.",
  "In every deal be kind, reasonable, and honest. It won't win you 100% of deals, but it will turn all of your wins into continued business with the client.",
  "Know your customer’s business better than they do. Then you can provide real value.",
  "A successful salesperson does what unsuccessful salespeople won’t do.",
  "Track your sales metrics.",
  "People may forget what you say, but they’ll never forget how you made them feel.",
  "The product is almost always the least important thing in the closing deal.",
  "Start with specific niche markets.",
  "Use lead scoring to prioritize your prospects.",
  "Connect with the decision-makers.",
  "Make Exciting your sales pitch.",
  "Listen to your prospects.",
  "Emphasize the risks and opportunities.",
  "Develop the right mindset.",
  "Go beyond business.",
  "Reach out to SQL immediately.",
  "Follow the PAS framework.",
  "Target your Existing customers.",
  "Make the prospect realize that it is a final offer.",
  "Employ email automation.",
  "Keep track of customers’ records.",
  "Organize Healthy competition within the sales team.",
  "Strengthen your Buyer’s persona.",
  "Have a clear understanding of your clients.",
  "Bring the USP to your customers with a free trial.",
  "Align your sales and marketing team.",
  "Run sales offer unexpectedly."
];

export default class extends Controller {
  static targets = ['submit'];
  static values = { userType: String, delay: Number };

  connect() {
    try {
      this.toltalFilterCount = this.element.querySelectorAll('[data-controller="search--choices"], [data-controller="search--checkboxes"], [data-controller="search--size-range"]').length;
      this.initCount = 0;
      this.resultTarget = document.getElementById('records');
      this.isDisabled = false;
      this.userType = this.userTypeValue || "free";
      this.delay = this.userType === "free" ? (this.delayValue || 10) : 0; // Default delay for free users is 10 seconds
      this.saveSearchModal = this.initSaveSearchModal();
      window.saveSearchModal = this.saveSearchModal;
      // Bind methods for proper context
      this.bindMethods([
        'searchAction', 'clearSearch', 'clearSearchElement', 'clearSearchAll',
        'gotoPage', 'triggerSearch', 'toggleLoadingSearch', 'checkFilterInit',
        'updateActiveSearch', 'submitSearch', 'pagingAction', 'toggleAccordion',
        'toggleDisableSearchTerms', 'toggleSubmitButton', 'toggleOneClickButton',
        'buildFilterURL'
      ]);

      document.addEventListener("search--pagination:change", this.gotoPage);
      document.addEventListener("search--choices:init", this.checkFilterInit);
      document.addEventListener("search--checkboxes:init", this.checkFilterInit);
      document.addEventListener("search--size-range:init", this.checkFilterInit);
      // this.submitTarget.addEventListener("click", this.submitSearch);
    } catch (error) {
      console.error("Error in connect method:", error);
    }
  }

  bindMethods(methods) {
    methods.forEach(method => {
      try {
        // Check if the method exists and is a function before binding
        if (typeof this[method] === 'function') {
          this[method] = this[method].bind(this);
        } else {
          console.warn(`Method "${method}" is not defined or not a function.`);
        }
      } catch (error) {
        console.error(`Error binding method "${method}":`, error);
      }
    });
  }

  disconnect() {
    try {
      document.removeEventListener("search--pagination:change", this.gotoPage);
      document.removeEventListener("search--choices:init", this.checkFilterInit);
      document.removeEventListener("search--checkboxes:init", this.checkFilterInit);
      document.removeEventListener("search--size-range:init", this.checkFilterInit);
      // this.submitTarget.removeEventListener("click", this.submitSearch);
    } catch (error) {
      console.error("Error in disconnect method:", error);
    }
  }

  submitSearch(event) {
    try {
      console.log('submitSearch', new Date().toLocaleString());
      event.preventDefault();
      this.searchAction({ event });
    } catch (error) {
      console.error("Error in submitSearch method:", error);
    }
  }

  disableSubmitButton() {
    this.isDisabled = true;
    this.remainingTime = this.delay;
    this.submitTarget.disabled = true;
    this.submitTarget.textContent = `Wait ${this.remainingTime} seconds`;

    const interval = setInterval(() => {
      this.remainingTime -= 1;
      if (this.remainingTime <= 0) {
        clearInterval(interval);
        this.enableSubmitButton();
      } else {
        this.submitTarget.textContent = `Wait ${this.remainingTime} seconds`;
      }
    }, 1000);
  }

  enableSubmitButton() {
    this.isDisabled = false;
    this.submitTarget.disabled = false;
    this.submitTarget.textContent = "Submit";
  }

  triggerSearch(event) {
    try {
      console.log('triggerSearch', new Date().toLocaleString());
      document.getElementById('page').value = 1;
      this.updateActiveSearch();
      this.searchAction({ event });
    } catch (error) {
      console.error("Error in triggerSearch method:", error);
    }
  }

  gotoPage(event) {
    try {
      console.log('gotoPage', new Date().toLocaleString());
      const page = event.detail.page;
      document.getElementById('page').value = page;
      this.pagingAction();
    } catch (error) {
      console.error("Error in gotoPage method:", error);
    }
  }

  checkFilterInit(event) {
    try {
      this.initCount += 1;
      if (this.initCount >= this.toltalFilterCount) {
        console.log('checkFilterInit', new Date().toLocaleString());
        setTimeout(() => this.searchAction(), 1000);
      }
    } catch (error) {
      console.error("Error in checkFilterInit method:", error);
    }
  }

  updateActiveSearch() {
    try {
      const badge = document.querySelector(`.badge-total`);
      const mobileBadge = document.querySelector('.badge-mobile-total');
      const values = document.getElementsByClassName('group/accordion filled-search');

      if (badge) {
        badge.innerHTML = values.length;
        this.element.classList.toggle('is-search', values.length > 0);
      }

      if (mobileBadge) {
        mobileBadge.innerHTML = values.length;
        mobileBadge.classList.toggle('hidden', values.length === 0);
      }
    } catch (error) {
      console.error("Error in updateActiveSearch method:", error);
    }
  }

  toggleLoadingSearch(containerId = 'records') {
    try {
      this.resultTarget = document.getElementById(containerId);
      const tip = tips[Math.floor(Math.random()*tips.length)];
      const tipsString = JSON.stringify(tips);
      this.resultTarget.innerHTML = `<div id="slogan-container" data-controller="anime--slogan" class="grow flex flex-col items-center justify-center mt-1/2 h-full">
                                        <div role="status">
                                          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                        <div id="slogan" data-anime--slogan-target="text" class="text-xl text-center font-light text-gray-600 dark:text-gray-400 mt-4 opacity-0 transition duration-500 position-relative anime--slogan">${tip}</div>
                                      </div>`;
      const sloganContainer = document.getElementById('slogan-container');
      if (sloganContainer) {
        sloganContainer.setAttribute('data-content', tipsString);
      }
    } catch (error) {
      console.error("Error in toggleLoadingSearch method:", error);
    }
  }

  searchAction(options = {}) {
    try {
      const start = new Date();
      const button = options['reset'] ? this.element.querySelector('#resetSearch') : this.element.querySelector('#searchAction');
      const url = this.buildFilterURL(options);
      console.log(`Submit started at ${new Date().toLocaleString()}: ${Date.now() - start} ms`);

      if (localStorage.getItem('search_url') == url && options.event) {
        return;
      } else {
        this.toggleLoadingSearch();
        this.toggleDisableSearchTerms(true);
        this.toggleSubmitButton(button);
        document.getElementById('page').value = 1;
        localStorage.setItem('search_url', url);
      }

      fetch(url, {
        method: this.element.getAttribute('method') || 'post',
        headers: { Accept: "text/vnd.turbo-stream.html" }
      })
      .then(r => r.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
        history.pushState({}, null, url);
        this.toggleDisableSearchTerms(false);
        this.updateActiveSearch();
        this.toggleSubmitButton(button);
        if (this.delay > 0 && this.userType === "free") {
          this.disableSubmitButton();
        }
        console.log(`Time elapsed started at ${start.toLocaleString()}: ${Date.now() - start} ms`);
      })
      .catch(error => console.error("Error in fetch:", error));
    } catch (error) {
      console.error("Error in searchAction method:", error);
    }
  }

  pagingAction() {
    try {
      this.toggleLoadingSearch('table-results');
      this.toggleDisableSearchTerms(true);

      const start = new Date();
      const url = this.buildFilterURL({ ss: true, submit: 'paging' });
      console.log(`Paging started at ${new Date().toLocaleString()}: ${Date.now() - start} ms`);

      fetch(url, {
        method: this.element.getAttribute('method') || 'post',
        headers: { Accept: "text/vnd.turbo-stream.html" }
      })
      .then(r => r.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
        history.pushState({}, null, url);
        this.toggleDisableSearchTerms(false);
        console.log(`Time elapsed started at ${start.toLocaleString()}: ${Date.now() - start} ms`);
      })
      .catch(error => console.error("Error in fetch:", error));
    } catch (error) {
      console.error("Error in pagingAction method:", error);
    }
  }

  buildFilterURL(options = {}) {
    try {
      const listSelect = document.getElementById('listSelect');
      if (listSelect) {
        document.getElementById('list_id').value = listSelect.value;
      }
      const action = this.element.getAttribute('action');
      const params = new FormData(this.element);
      const ignoreParams = ['search_terms'];

      if (!options['ss']) {
        ignoreParams.push('ss[total_count]', 'ss[total_added]', 'ss[total_pages]');
      }

      let filteredParams = new FormData();
      for (let [key, value] of params.entries()) {
        if (!ignoreParams.includes(key)) {
          const element = document.querySelector(`[name="${key}"]`);
          if (element.closest('.filled-search') || element.closest('.default-search')) {
            filteredParams.append(key, value);
          }
        }
      }
      filteredParams.append('submit', options['submit'] || 'search');
      return `${action}?${new URLSearchParams(filteredParams).toString()}`;
    } catch (error) {
      console.error("Error in buildFilterURL method:", error);
      return '';
    }
  }

  clearSearch(event) {
    try {
      this.clearSearchElement(event.currentTarget.dataset.key, true);
    } catch (error) {
      console.error("Error in clearSearch method:", error);
    }
  }

  clearSearchAll(event) {
    try {
      console.log('clearSearchAll', new Date().toLocaleString());
      Array.from(document.getElementsByClassName('remove-search')).forEach(element => {
        this.clearSearchElement(element.dataset.key, false);
      });
      this.searchAction({ reset: true });
    } catch (error) {
      console.error("Error in clearSearchAll method:", error);
    }
  }

  clearSearchElement(key, reset = false) {
    try {
      const container = document.getElementById(`accordion-flush-body-${key}`);
      const accordion = document.getElementById(`accordion-flush-${key}`);
      const button = document.getElementById(`reset-${key}`);

      if (container) {
        accordion.classList.remove('filled-search');
        const elements = container.querySelectorAll('select, input');

        elements.forEach(element => {
          if (element.type === 'checkbox' || element.type === 'radio') {
            element.checked = false;
          } else {
            element.value = '';
          }
        });

        if (button) button.click();
      }
    } catch (error) {
      console.error("Error in clearSearchElement method:", error);
    }
  }

  toggleAccordion(event) {
    try {
      const checkbox = event.currentTarget.querySelector(`#${event.currentTarget.getAttribute('for')}`);
      if (checkbox) {
        checkbox.checked = true;
      }
    } catch (error) {
      console.error("Error in toggleAccordion method:", error);
    }
  }

  toggleDisableSearchTerms(status) {
    try {
      Array.from(document.getElementsByName('search_terms')).forEach(element => {
        element.disabled = status;
      });
    } catch (error) {
      console.error("Error in toggleDisableSearchTerms method:", error);
    }
  }

  toggleSubmitButton(button) {
    try {
      button ||= this.element.querySelector('#searchAction');
      const originalContent = button.innerHTML;
      const loadingContent = button.getAttribute('data-turbo-submits-with');

      button.setAttribute('data-turbo-submits-with', originalContent);
      button.innerHTML = loadingContent;
      button.disabled = !button.disabled;
    } catch (error) {
      console.error("Error in toggleSubmitButton method:", error);
    }
  }

  toggleOneClickButton() {
    try {
      const button = document.getElementById('addOneClick');
      const counter = document.getElementById('counter');

      if (button) {
        if (counter) {
          button.classList.remove("opacity-65", "cursor-not-allowed");
          button.disabled = false;
        } else {
          button.classList.add("opacity-65", "cursor-not-allowed");
          button.disabled = true;
        }
      }
    } catch (error) {
      console.error("Error in toggleOneClickButton method:", error);
    }
  }

  initSaveSearchModal() {
    const that = this;
    // set the modal menu element
    const $targetEl = document.getElementById('save-search-modal');

    if (!$targetEl) { return }

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'save-search-modal bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: 'save-search-modal',
      override: true
    };

    return new Modal($targetEl, options, instanceOptions);
  }

  openSaveSearchModal() {
    this.saveSearchModal.show();
  }

  closeSaveSearchModal() {
    this.saveSearchModal.hide();
  }
}
